exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bacteriologia-js": () => import("./../../../src/pages/bacteriologia.js" /* webpackChunkName: "component---src-pages-bacteriologia-js" */),
  "component---src-pages-coagulacion-js": () => import("./../../../src/pages/coagulacion.js" /* webpackChunkName: "component---src-pages-coagulacion-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-coprologia-js": () => import("./../../../src/pages/coprologia.js" /* webpackChunkName: "component---src-pages-coprologia-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-hematologia-js": () => import("./../../../src/pages/hematologia.js" /* webpackChunkName: "component---src-pages-hematologia-js" */),
  "component---src-pages-hormonas-y-marcadores-js": () => import("./../../../src/pages/hormonas_y_marcadores.js" /* webpackChunkName: "component---src-pages-hormonas-y-marcadores-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inmunologia-js": () => import("./../../../src/pages/inmunologia.js" /* webpackChunkName: "component---src-pages-inmunologia-js" */),
  "component---src-pages-quimica-clinica-js": () => import("./../../../src/pages/quimica_clinica.js" /* webpackChunkName: "component---src-pages-quimica-clinica-js" */),
  "component---src-pages-uroanalisis-js": () => import("./../../../src/pages/uroanalisis.js" /* webpackChunkName: "component---src-pages-uroanalisis-js" */)
}

